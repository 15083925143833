
import { defineComponent, onMounted, ref,computed } from "vue";
import ApiService from "@/core/services/ApiService";
//import  Master from "@/models/MasterModel"
import * as Mast from "@/models/MasterModel"
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";

export default defineComponent({
  name: "address-templates-table",
  components: {},
  props: {
    widgetClasses: String,
    
  },
  setup(props) {
    
    const router = useRouter();
    const store = useStore();

    
   const slaveTemplateId = computed(() => store.getters.curSlaveTemplateId)
    
    const addresses = ref<Mast.AddressTemplate[]>([]as Mast.AddressTemplate[]);
    onMounted(() => {
      getAddressTemplates()

    })
    
    //todo naar aparte file
    const getAddressTemplates = () =>{
        const postData ={
        "searchString": '',
        "parentIds": [slaveTemplateId.value],
        "pageSize": 10000,
        "pageNumber": ''
         }as any;

        ApiService.post("/template/addresstemplates",postData)
        .then(({ data }) => {
            console.log('getaddresstemplatesresponse data:');
            console.log({...data});
            addresses.value = data.results; 
        })
        .catch(({ response }) => {
          console.log("Fout getaddresstemplates")
           
        });
    }

    const clickNewAddressTemplate =() => {
          console.log("goNewaddressesTemplate");
          //je mag alleen adress toevoegen als de slave al in de DB.
          if(slaveTemplateId.value == 0 ){
            Swal.fire({
            title: 'Slave-template must be submitted before adding address',
            icon:"warning",  
            buttonsStyling: true,
            confirmButtonText: "Ok",
                    
          }).then((result) => { 
            return
          });
      }
      else{
              store.commit('setAddressTemplateId',0)
              router.push({ name: "editaddresstemplate"});
           }
 
    }

    const clickEditAddressTemplate =(data) => {
      console.log("goEditAddressTemplate");
      console.log(data)
      
      store.commit('setAddressTemplateId',data.id)
      router.push({ name: "editaddresstemplate"});
    }

    
    
    const clickDeleteAddressTemplate =(data:Mast.AddressTemplate) => {
      Swal.fire({

          title: 'Are you sure to delete address-template '+data.name +' ?',
          showCancelButton: true,
          icon:"warning",  
          buttonsStyling: true,
          confirmButtonText: "Ok",
          cancelButtonText: "Cancel",
          
        }).then((result) => { 
          if(result.isConfirmed){
              deleteAddressTemplate(data)
          } 
        });
    }

    const deleteAddressTemplate =(data) => {
      console.log("deleteAddressTemplate:");
      console.log(data)
   
        ApiService.post("/template/addresstemplatedelete",data as any)
        .then(({ data }) => {
            console.log('delete addresstemplate response data:');
            console.log({...data});
            getAddressTemplates();
        })
        .catch(({ response }) => {
          console.log("Fout addresstemplatedelete")
        });

    }


    return {
      addresses,
      clickNewAddressTemplate,
      clickEditAddressTemplate,
      clickDeleteAddressTemplate
    };
  },
});
