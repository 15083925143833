
import {
  defineComponent,
  ref,
  PropType,
  onMounted,
  computed,
  watch,
} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
//import AddressEditForm from "@/components/AddressEditFormModal.vue";
import * as Mast from "@/models/MasterModel";
import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
import AddressTemplatesTable from "@/components/addressTemplatesTable.vue";
import BnPHelpers from "@/assets/ts/_utils/BnPHelpers";
import { useStore } from "vuex";

export default defineComponent({
  name: "slave-template-edit-form",
  components: {
    //KTModalCard,
    AddressTemplatesTable,
    //AddressEditForm,
    ErrorMessage,
    Field,
    Form,
  },
  props: {},
  setup(props) {
    const router = useRouter();
    const store = useStore();

    const slaveTemplateId = computed(() => store.getters.curSlaveTemplateId);

    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newAddressModalRef = ref<null | HTMLElement>(null);

    const protocols = ref<Mast.GenericOption[]>([{} as Mast.GenericOption]);
    const baudRates = ref<Mast.GenericOption[]>([{} as Mast.GenericOption]);
    const networks = ref<Mast.GenericOption[]>([{} as Mast.GenericOption]);
    const intervals = ref<Mast.GenericOption[]>([{} as Mast.GenericOption]);

    var curAgenda = [{}];

    var NewSlaveTemplateData = ref<Mast.SlaveTemplate>({
      id: 0,
      name: "",
      networks: [],
      defaultNetwork: {
        id: 0,
        genericOptionTypeId: 0,
        int: 0,
        float: 0,
        string: "",
        selected: false,
      },
      defaultNetworkId: 0,
      baudRates: [],
      defaultBaudrateId: 0,
      protocols: [],
      defaultProtocol: {
        id: 0,
        genericOptionTypeId: 0,
        int: 0,
        float: 0,
        string: "",
        selected: false,
      },
      defaultProtocolId: 0,
      intervals: [],
      intervalId: 0,
      addressTemplates: [],
    });

    const validationSchema = Yup.object().shape({
      name: Yup.string().required().label("Name"),
      // interval: Yup.number().required().min(1).label("interval"),
      // protocol: Yup.number().required().label("protocol"),
      // ipAddress: Yup.string().required().label("ipAddress"),
      // port: Yup.number(),
      // displayName: Yup.string().required().label("displayName"),
      // description: Yup.string(),
      // slaveTypeId: Yup.number().required().min(1).label("slaveTypeId"),
    });

    const getSlaveTemplateById = (id) => {
      console.log("getSlaveTemplateById id:");
      console.log(id);

      ApiService.get2("/template/slavetemplateget?id=" + id)
        .then(({ data }) => {
          console.log("getSlave response data:"); //todo geert
          console.log({ ...data }); //todo geert

          NewSlaveTemplateData.value = data as Mast.SlaveTemplate;
        })
        .catch(({ response }) => {
          console.log("Fout getSlaveById");
        });
    };

    //Todo moet naar aparte file wordt vaker gebruikt
    // const getOptions = (name:string,list) => {
    //    console.log("get"+name);
    //     ApiService.get2("/template/"+name)
    //     .then(({ data }) => {
    //         console.log('get'+name+' response data:');//todo geert
    //         console.log({...data});//todo geert
    //         list.value = data as Mast.GenericOption[] ;

    //     })
    //     .catch(({ response }) => {
    //       console.log("Fout get"+name)
    //     });
    // };

    const clickTest = () => {
      console.log("neWSlaveData:");
      console.log(NewSlaveTemplateData.value);
    };

    const submit = () => {
      console.log("NewSlavedata");
      console.log(NewSlaveTemplateData.value);
      if (!submitButtonRef.value) {
        return;
      }

      //Disable button and busy indicator
      submitButtonRef.value.disabled = true;

      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      ApiService.post(
        "/template/slavetemplateupdate",
        NewSlaveTemplateData.value as any
      )
        .then(({ data }) => {
          console.log("submit Slave response data:"); //todo geert
          console.log({ ...data }); //todo geert
          router.push({ name: "templateadministration" });
        })
        .catch(({ response }) => {
          console.log("Fout SlaveTemplateUpdate");
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;
            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
        });
    };

    const count = ref(1);
    const cNetworks2 = computed({
      get: () => count.value * 2,
      set: (val) => {
        count.value = val - 1;
      },
    });

    const cNetworks = computed({
      get: () => count.value * 2,
      set: (val) => {
        count.value = val - 1;
      },
    });

    const clickCancel = () => {
      router.go(-1);
    };

    onMounted(() => {
      if (slaveTemplateId.value != 0) {
        getSlaveTemplateById(slaveTemplateId.value);
        console.log("NewSlaveData is nu:");
        console.log(NewSlaveTemplateData.value.name);
      }
      BnPHelpers.getOptions("/template/protocols", protocols);
      BnPHelpers.getOptions("/template/baudrates", baudRates);
      BnPHelpers.getOptions("/template/networks", networks);
      BnPHelpers.getOptions("/template/intervals", intervals);
    });

    return {
      clickTest,
      NewSlaveTemplateData,
      validationSchema,
      submit,
      submitButtonRef,
      newAddressModalRef,
      clickCancel,
      protocols,
      networks,
      baudRates,
      intervals,
      cNetworks,
    };
  },
});
