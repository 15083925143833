
    import { defineComponent, onMounted} from "vue";
    //import TableWidget9 from "@/components/widgets/tables/Widget9.vue";
    import slaveTemplateEditform from "@/components/SlaveTemplateEditForm.vue";
    import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
    //import { MenuComponent } from "@/assets/ts/components";
    //import masterstable from "@/components/MastersTable.vue"

    export default defineComponent({
        name: "slave-edit-page",
        components: {
            slaveTemplateEditform,

        },
        setup() {
            onMounted(() => {
                //setCurrentPageBreadcrumbs("Charts", ["Widgets"]);
                setCurrentPageTitle("Edit SlaveTemplate");
                //MenuComponent.reinitialization();
            });

                    
            return {
                slaveTemplateEditform
            };
        },


    });
